import { serviceConsoleApi } from '@/utils/request'
import { CancelToken } from 'axios'

export interface IperiodParams {
    period: 'last30Days' | 'last7Days' | 'last24Hours'
}

export const getCampaignStatistics = (campaignId: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/reports/campaigns/${campaignId}`,
        {
            cancelToken,
        },
    )

export const getCampaignTrendUniqueOpen = (campaignId: any, params?: IperiodParams, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/reports/campaigns/${campaignId}/trend/unique-open`,
        {
            cancelToken,
            params,
        },
    )

export const getCampaignTrendUniqueClick = (campaignId: any, params?: IperiodParams, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/reports/campaigns/${campaignId}/trend/unique-click`,
        {
            cancelToken,
            params,
        },
    )

export const getCampaignTrendSpamReport = (campaignId: any, params?: IperiodParams, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/reports/campaigns/${campaignId}/trend/spam-report`,
        {
            cancelToken,
            params,
        },
    )

export const getCampaignTrendUnsubscription = (campaignId: any, params?: IperiodParams, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/reports/campaigns/${campaignId}/trend/unsubscription`,
        {
            cancelToken,
            params,
        },
    )

export const getCampaignDomainsTotalOpen = (campaignId: any, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/reports/campaigns/${campaignId}/domains/total-open`,
        {
            cancelToken,
            params,
        },
    )

export const getCampaignDomainsTotalClick = (campaignId: any, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/reports/campaigns/${campaignId}/domains/total-click`,
        {
            cancelToken,
            params,
        },
    )

export const getCampaignLinks = (campaignId: any, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/reports/campaigns/${campaignId}/links`,
        {
            cancelToken,
            params,
        },
    )

export const getCampaignSubscribersTotalClick = (campaignId: any, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/reports/campaigns/${campaignId}/contacts-activity/total-click`,
        {
            cancelToken,
            params,
        },
    )

export const getCampaignSubscribersTotalClickDownload = (campaignId: any, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/reports/campaigns/${campaignId}/contacts-activity/total-click/download`,
        {
            cancelToken,
            responseType: 'blob',
            params,
        },
    )

export const getCampaignSubscribersTotalOpen = (campaignId: any, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/reports/campaigns/${campaignId}/contacts-activity/total-open`,
        {
            cancelToken,
            params,
        },
    )

export const getCampaignSubscribersTotalOpenDownload = (campaignId: any, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/reports/campaigns/${campaignId}/contacts-activity/total-open/download`,
        {
            cancelToken,
            responseType: 'blob',
            params,
        },
    )

export const getCampaignSubscribersSpamReport = (campaignId: any, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/reports/campaigns/${campaignId}/contacts-activity/spam-report`,
        {
            cancelToken,
            params,
        },
    )

export const getCampaignSubscribersBounceReport = (campaignId: any, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/reports/campaigns/${campaignId}/contacts-activity/bounce`,
        {
            cancelToken,
            params,
        },
    )

export const getCampaignSubscribersUnsubscription = (campaignId: any, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/reports/campaigns/${campaignId}/contacts-activity/unsubscription`,
        {
            cancelToken,
            params,
        },
    )

export const getListContactsStatistics = (listId: any, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/reports/recipients/${listId}/contacts`,
        {
            cancelToken,
            params,
        },
    )

export const getListSpamStatistics = (listId: any, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/reports/recipients/${listId}/spam`,
        {
            cancelToken,
            params,
        },
    )

export const getListEngagementStatistics = (listId: any, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/reports/recipients/${listId}/engagement `,
        {
            cancelToken,
            params,
        },
    )

export const generateEmailSentReport = (campaignId: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `reports/campaigns/${campaignId}/email-sent`,
        {
            cancelToken,
        },
    )

export const getEmailSentReport = (campaignId: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `reports/campaigns/${campaignId}/email-sent-download`,
        {
            headers: {
                'Cache-Control': 'no-cache',
            },
            cancelToken,
        },
    )

export const downloadLinkContacts = (campaignId: any, params: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `reports/campaigns/${campaignId}/links/contacts`,
        {
            params,
            responseType: 'blob',
            cancelToken,
        },
    )

export const getListTrendSubscription = (listId: any, params?: IperiodParams, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/reports/recipients/${listId}/trend/subscription`,
        {
            cancelToken,
            params,
        },
    )

export const getListTrendUnsubscription = (listId: any, params?: IperiodParams, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/reports/recipients/${listId}/trend/unsubscription`,
        {
            cancelToken,
            params,
        },
    )

export const getListTrendUniqueOpen = (listId: any, params?: IperiodParams, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/reports/recipients/${listId}/trend/unique-open`,
        {
            cancelToken,
            params,
        },
    )

export const getListTrendUniqueClick = (listId: any, params?: IperiodParams, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/reports/recipients/${listId}/trend/unique-click`,
        {
            cancelToken,
            params,
        },
    )

export const getListTotalOpenContactsActivity = (listId: any, params?: IperiodParams, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/reports/recipients/${listId}/contacts-activity/total-open`,
        {
            cancelToken,
            params,
        },
    )

export const getListTotalClickContactsActivity = (listId: any, params?: IperiodParams, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/reports/recipients/${listId}/contacts-activity/total-click`,
        {
            cancelToken,
            params,
        },
    )

export const getListDomainsTotalOpen = (listId: any, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/reports/recipients/${listId}/domains/total-open`,
        {
            cancelToken,
            params,
        },
    )

export const getListDomainsTotalClick = (listId: any, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/reports/recipients/${listId}/domains/total-click`,
        {
            cancelToken,
            params,
        },
    )

export const getListLinks = (listId: any, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/reports/recipients/${listId}/links`,
        {
            cancelToken,
            params,
        },
    )

export const getListSubscribersSpamReport = (listId: any, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/reports/recipients/${listId}/spam`,
        {
            cancelToken,
            params,
        },
    )

export const getReportHardBounce = (emailAddress: string, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `reports/deliverability/email/${emailAddress}`,
        {
            cancelToken,
            params,
        },
    )
